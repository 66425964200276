<template>
    <v-card-text class="mt-0 pt-0">
        <v-card-subtitle
            style="display: flex; justify-content: center;"
            class="mt-0 pt-0"
        >
            <span> {{ $t('awards.subTitleWinner') }}</span>
        </v-card-subtitle>

        <ol
            style="line-height: 1.8em;"
            class="winner-simple-text"
        >
            <li
                v-for="
                    (item,
                     index)
                        in
                            areasCompetition"
                :key="index"
                class="area"
                @click="$router.push('' + (index + 1))"
            >
                <div style="margin-left: 1.5em">
                    <span style="font-size: 1.5em;"> {{ item.name }}</span>
                </div>
            </li>
        </ol>

        <v-card-subtitle
            style="display: flex; justify-content: center;"
            class="mt-3 pt-0"
        >
            <span
                class="text-h7 mt-3"
            >
                Recordar las
                <a
                    style="color: #006ff5; text-decoration: underline;"
                    href="https://irisglobalback.digitabilities.com/download/bases-del-concurso-mi-mision.pdf"
                    target="_blank"
                >bases del concurso</a>
            </span>
        </v-card-subtitle>
    </v-card-text>
</template>

<script>
import listareasCompetition from '@/data/areasCompetition'

export default {
    name: 'AreasList',
    data: function () {
        return {
            areasCompetition: listareasCompetition
        }
    }
}
</script>

<style scoped>
.area{
  margin-top: 10px;
  cursor: pointer;
}

.area:hover{
font-size: 1.3em;
}

@media (min-width: 700px) {
  .winner-simple-text {
    margin-right: 3em;
    margin-left: 3em;
  }
}
</style>
